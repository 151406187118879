import {http} from '@/services/config';

export default {
    create: body => http.post(`/api/calls/register`, body),

    update: (id, body) => http.put(`/api/calls/update/${id}`, body),

    finish: id => http.get(`/api/calls/finish/${id}`),

    show: id => http.get(`/api/calls/show/${id}`),

    index: (filter, page, perPage) => http.get(`/api/calls/index?page=${page}`,
        {params: {...filter, perPage: !isNaN(parseInt(perPage)) ? perPage : null}}),

    print: data => http.post(`/api/calls/print-chat`, data),

    getCallsByClerk: (clerkId, page) => http.get(`/api/calls/by-clerk${clerkId ? `/${clerkId}` : ''}?page=${page}`),

    getCallsBySector: (sectorId, page) => http.get(`/api/calls/by-sector${sectorId ? `/${sectorId}` : ''}?page=${page}`),
}
