<template>
    <div>
    </div>
</template>

<script>

export default {
    name: 'FireComponent',

    methods: {
        fireSwal(...config) {
            let confirmButtonText, cancelButtonText, cancelButtonColor, denyButtonText, icon, text, denyButton, title, html, footer,
                allowOutsideClick, confirmButtonColor;

            for (const conf of config) {
                confirmButtonText = conf.confirmButtonText;
                confirmButtonColor = conf.confirmButtonColor;
                cancelButtonText = conf.cancelButtonText;
                cancelButtonColor = conf.cancelButtonColor;
                icon = conf.icon;
                text = conf.text;
                title = conf.title;
                denyButton = conf.denyButton;
                denyButtonText = conf.denyButtonText;
                html = conf.html;
                footer = conf.footer;
                allowOutsideClick = (typeof conf.allowOutsideClick === 'boolean') ? conf.allowOutsideClick : true;
            }

            const obj = {
                title: title ? title : 'Você tem certeza?',
                icon: icon ? icon : 'warning',
                text,
                html,
                footer,
                showCancelButton: true,
                showDenyButton: denyButton,
                confirmButtonColor: confirmButtonColor ? confirmButtonColor : '#3085d6',
                denyButtonColor: '#3085d6',
                cancelButtonColor: cancelButtonColor ? cancelButtonColor : '#d33' ,
                confirmButtonText: confirmButtonText ? confirmButtonText : 'Sim, Cancelar',
                cancelButtonText: cancelButtonText ? cancelButtonText : 'Não Cancelar',
                denyButtonText,
                allowOutsideClick,
            }

            //Passe o valor do item como 'remove' para remover;
            for (let findRemove in obj) {
                if (obj[findRemove] === 'remove') {
                    delete obj[findRemove]
                }
            }

            //icons: error, question, info, warning
            return this.$swal.fire(obj).then(async (result) => {
                return result;
            });
        }
    }
}
</script>
