import axios from 'axios';
import App from './App.vue';
import Store from '@/store';
import { createApp } from 'vue';
import Dialog from 'vue3-dialog';
import VTooltip from 'v-tooltip';
import '../registerServiceWorker';
import VueTheMask from 'vue-the-mask';
import router from '../router/router';
import * as Sentry from '@sentry/vue';
import Toast from 'vue-toastification';
import VueTelInput from 'vue-tel-input';
import 'vue-toastification/dist/index.css';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import 'vue-tel-input/dist/vue-tel-input.css';
import FireComponent from '@/components/FireComponent';
import ErrorComponent from '@/components/ErrorComponent';
import PermissionComponent from '@/components/PermissionComponent';

axios.defaults.withCredentials = true;

const app = createApp(App);

app.mixin(ErrorComponent);
app.mixin(FireComponent);
app.mixin(PermissionComponent);

app.use(router);
app.use(VueSweetalert2);
app.use(VTooltip);
app.use(VueTheMask);
app.use(Toast);
app.use(VueTelInput);
app.use(Dialog);
app.use(Store);

Sentry.init({
    app,
    dsn: process.env.VUE_APP_DNS_SENTRY,
    integrations: [
        new Sentry.BrowserTracing({
            tracePropagationTargets: ['localhost', process.env.VUE_APP_HOST_SENTRY],
            routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        }),
        new Sentry.Replay(),
    ]
});

app.mount('#app');
