<template>
    <div></div>
</template>

<script>

import stores from '@/store';

export default {
    name: 'PermissionComponent',

    methods: {
        async checkPermission(permissions) {
            const hasPermission = permissions.every(permission => stores.getters['Permissionsx/hasPermission'](permission));

            if (!hasPermission) {
                return this.$router.push({name: 'LoginComponent'});
            }
        },
    }
}
</script>

