import {createStore} from 'vuex';
import Chatx from '@/vuex/chatx';
import Callsx from '@/vuex/callsx';
import Layoutx from '@/vuex/layoutx';
import Connectionx from '@/vuex/connectionx';
import Permissionsx from '@/vuex/permissionsx';

const store = createStore({
    modules: {
        Chatx: Chatx,
        Callsx: Callsx,
        Layoutx: Layoutx,
        Connectionx: Connectionx,
        Permissionsx: Permissionsx,
    }
});

export default store;
