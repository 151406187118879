import jwtDecode from 'jwt-decode';
import Auth from '@/services/Auth';
import Connection from '@/Enums/ConnectionEnum';

export default {
    namespaced: true,

    state: {
        user: null,
        socket: null,
        attemptsQR: 1,
        socketId: null,
        connections: [],
        imageBase64QR: '',
        isConnected: false,
        connectionLabel: '',
        connectionPercentage: 5,
        connectionStatus: Connection.Disconnected,
    },

    getters: {
        getIsConnected: state => {
            return (state.connectionStatus === 'CONNECTED') || (state.connectionStatus === 'inChat');
        }
    },

    mutations: {
        setIsConnected(state, boolean) {state.isConnected = boolean},
        setSocket(state, value) {state.socket = value},
        setSocketId(state, id) {state.socketId = id},
        setConnectionStatus(state, status) {state.connectionStatus = status},
        setConnectionLabel(state, label) {state.connectionLabel = label},
        setConnectionPercentage(state, label) {state.connectionPercentage = label},
        setAttemptsQR(state, attempts) {state.attemptsQR = attempts},
        setQrCode(state, file) {state.imageBase64QR = file},
        setConnections(state, connections) {state.connections = connections},
    },

    actions: {
        ActionSetConnectionLabel({commit, dispatch}, value) {
            let statusLabel;
            let percentage;
            switch (value) {
                case 'isLogged' //indica que conseguiu logar e vai recuperar a sessão
                :percentage = 60; statusLabel = Connection.RecoverSession; break;
                case 'inChat': commit('setIsConnected', true); percentage = 100; statusLabel = ''; break;

                case 'notLogged'  //nao conseguiu logar
                :percentage = 30; statusLabel = Connection.WaitingQR; break;
                case 'desconnectedMobile': //na hora que desconecta pelo celular chama esse método!
                    commit('setIsConnected', false);
                    percentage = 55; statusLabel = Connection.WaitingScanner; break;
                case 'qrReadSuccess':
                    percentage = 85; statusLabel = Connection.SyncingBR; break;
                case 'qrReadFail':
                case 'browserClose':
                case 'qrReadError':
                case 'autocloseCalled':
                case 'serverClose':
                    percentage = 100; statusLabel = Connection.FailQr; break;
            }

            commit('setConnectionLabel', statusLabel);
            commit('setConnectionPercentage', percentage);
            dispatch('ActionSetConnectionStatus', value)
        },

        ActionChangeSocket({commit}, socket) {
            commit('setSocket', socket);
        },

        ActionSetConnectionStatus({commit}, status) {
            commit('setConnectionStatus', status);
            commit('setIsConnected', status === 'inChat');
        },

        ActionRefreshToken({state}, payload) {
            const expiration = jwtDecode(payload.token).exp;
            const now = parseInt(Date.now().toString().slice(0, 10));

            //todo verificar qual é o certo e se vai parar os erros no sentry.io (5/09/2023);
            //O certo é: now > expiration
            if (now < expiration) {
                Auth.refreshTokenSecurity(payload.refreshToken).then(resp => {
                    localStorage.setItem('refresh_security_token', resp.data.refreshToken);
                    localStorage.setItem('security_token', resp.data.securityToken);
                }).catch(err => {
                    console.log(err)
                    localStorage.clear();
                    this.$router.push({name: 'LoginComponent'});

                    state.socket?.disconnect();
                });
            }
        },
    },
};
