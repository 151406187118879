export default Object.freeze({
    Connected: 'CONNECTED',
    Disconnected: 'DISCONNECTED',
    Connecting: 'RESUMING',
    Syncing: 'SYNCING',
    Starting: 'Iniciando',
    ConectingBR: 'Conectando',
    SyncingBR: 'Sincronizando',
    RecoverSession: 'Recuperando sessão',
    ConnectedBR: 'Conectado',
    WaitingScanner: 'Esperando Scannear QR Code',
    SuccessQr: 'QR Scaneado com sucesso',
    FailQr: 'Ocorreu um erro!',
    Trying_Connect: 'CONNECTING',
    WaitingReadQr: 'WAITING-READ-QR',
    LoadingChat: 'Carregando chat',
    AlmostThere: 'Quase lá',
    WaitingQR: 'Aguardando QR Code',
});
