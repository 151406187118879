import * as ApexCharts from 'apexcharts';
import DashboardEnum from '@/Enums/DashboardEnum';
import Support from '@/services/Dashboard/Support';

export default {
    namespaced: true,

    state: {
        now: '',
        chart: null,
        profile: {},
        image: null,
        evaders: [],
        contacts: [],
        imageBag: [],
        timeLeft: 60,
        pinned: false,
        isClerk: false,
        notifyId: null,
        isLoading: null,
        isSupport: false,
        monthlyLimit: {},
        notifications: [],
        imageCropped: null,
        messagesUnseen: [],
        profileImage: null,
        hasConfigBd: false,
        internalChat: false,
        countsDashboard: {},
        campaignUpdates: [],
        isClerkAdmin: false,
        officialChat: false,
        isClerkCommon: false,
        quantityMsgErrors: 0,
        loadingEvaders: false,
        commonUserMode: false,
        dataClientProfile: {},
        bestOptionOfServer: '',
        paginationCallsPage: 1,
        loadingPortChart: false,
        chartPortAnalysis: null,
        chartCallsByClerk: null,
        countdownStarted: false,
        countsDashboardUsers: {},
        mobileShowContacts: true,
        exceededMonthLimit: false,
        monthlyMessagesAverage: 0,
        chartPortAnalysisPie: null,
        chartCallsByCategory: null,
        messagesEditedHistoric: [],
        chartHistoricOfSends: null,
        showTransmissionList: false,
        chartMessageTypesSend: null,
        chartAverageTimeClerk: null,
        loadingCardsDashUsers: false,
        spinnerLoadingChatCall: false,
        chartDashboardUsersSends: null,
        chartDashboardUsersEvaders: null,
        showSuperTransmissionList: false,
        chartCurrentlyCallsByClerk: null,
        spinnerLoadingTimelineCall: false,
        chartCurrentlyCallsBySector: null,
        usersDataToChartUsersSurpassers: null,
        spinnerLoadingSupportDashboard: false,
        chartDataCurrentlyCallClerkSelected: {},
        chartDataCurrentlyCallSectorSelected: {},
        loadingSpinnerHistoricEditMessage: false,
        chartDashboardQuantityCallsLastDays: null,
    },

    mutations: {
        setImage(state, img) {state.image = img},
        setChart(state, chart) {state.chart = chart},
        setNotifyId(state, id) {state.notifyId = id},
        setDateActual(state, hour) {state.now = hour},
        setImageBag(state, bag) {state.imageBag = bag},
        pinSidebar(state) {state.pinned = !state.pinned},
        setProfile(state, profile) {state.profile = profile},
        setEvaders(state, evaders) {state.evaders = evaders},
        setImageCropped(state, img) {state.imageCropped = img},
        setProfileImage(state, img) {state.profileImage = img},
        setTimeLeft(state, seconds) {state.timeLeft = seconds},
        setContacts(state, contacts) {state.contacts = contacts},
        setIfHasConfigBd(state, bool) {state.hasConfigBd = bool},
        changeLoading(state, boolean) {state.isLoading = boolean},
        defineIfAreClerk(state, boolean) {state.isClerk = boolean},
        setLoadingEvaders(state, bool) {state.loadingEvaders = bool},
        defineIfAreSupport(state, boolean) {state.isSupport = boolean},
        defineIfAreClerkAdmin(state, bool) {state.isClerkAdmin = bool},
        setMessagesUnseen(state, value) {state.messagesUnseen = value},
        setMonthlyLimit(state, payload) {state.monthlyLimit = payload},
        setCountdownStarted(state, bool) {state.countdownStarted = bool},
        setIfIsInternalChatMode(state, bool) {state.internalChat = bool},
        setQuantityMsgErrors(state, int) {state.quantityMsgErrors = int},
        setIfIsOfficialChatMode(state, bool) {state.officialChat = bool},
        setCampaignUpdates(state, update) {state.campaignUpdates = update},
        setChartPortAnalysis(state, chart) {state.chartPortAnalysis = chart},
        setBestOptionOfServer(state, best) {state.bestOptionOfServer = best},
        setChartCallsByClerks(state, chart) {state.chartCallsByClerk = chart},
        alterShowContacts(state, boolean) {state.mobileShowContacts = boolean},
        defineIfAreClerkCommon(state, boolean) {state.isClerkCommon = boolean},
        setPaginationCallsPage(state, page) {state.paginationCallsPage = page},
        setCountsDashboard(state, quantity) {state.countsDashboard = quantity},
        setDataClientProfile(state, profile) {state.dataClientProfile = profile},
        setLoadingChartPortAnalysis(state, bool) {state.loadingPortChart = bool},
        setShowTransmissionList(state, bool) {state.showTransmissionList = bool},
        alterCommonUserMode(state) {state.commonUserMode = !state.commonUserMode},
        chartMessageTypesSend(state, chart) {state.chartMessageTypesSend = chart},
        setChartHistoricOfSends(state, chart) {state.chartHistoricOfSends = chart},
        setChartPortAnalysisPie(state, chart) {state.chartPortAnalysisPie = chart},
        setMonthlyMessagesAverage(state, int) {state.monthlyMessagesAverage = int},
        setLoadingCardsDashUsers(state, bool) {state.loadingCardsDashUsers = bool},
        setChartCallsByCategories(state, chart) {state.chartCallsByCategory = chart},
        setSpinnerLoadingChatCall(state, bool) {state.spinnerLoadingChatCall = bool},
        setNotifications(state, notifications) {state.notifications = notifications},
        setChartAverageTimeClerk(state, chart) {state.chartAverageTimeClerk = chart},
        setCountsDashboardUsers(state, quantity) {state.countsDashboardUsers = quantity},
        setIfIsExceededMonthLimit(state, exceeded) {state.exceededMonthLimit = exceeded},
        setChartDahsboardUsersSends(state, chart) {state.chartDashboardUsersSends = chart},
        setShowSuperTransmissionList(state, bool) {state.showSuperTransmissionList = bool},
        setMessagesEditedHistoric(state, messages) {state.messagesEditedHistoric = messages},
        toggleSidebar(state) {state.toggled = state.toggled == null ? true : !state.toggled},
        setSpinnerLoadingTimelineCall(state, bool) {state.spinnerLoadingTimelineCall = bool},
        setChartDahsboardUsersEvaders(state, chart) {state.chartDashboardUsersEvaders = chart},
        setChartCurrentlyCallsByClerk(state, chart) {state.chartCurrentlyCallsByClerk = chart},
        setChartCurrentlyCallsBySector(state, chart) {state.chartCurrentlyCallsBySector = chart},
        setUsersDataToChartUsersSurpassers(state, chart) {state.usersDataToChartUsersSurpassers = chart},
        setSpinnerLoadingSupportDashboard(state, boolean) {state.spinnerLoadingSupportDashboard = boolean},
        setChartDataCurrentlyCallClerkSelected(state, data) {state.chartDataCurrentlyCallClerkSelected = data},
        setChartDataCurrentlyCallSectorSelected(state, data) {state.chartDataCurrentlyCallSectorSelected = data},
        setLoadingSpinnerHistoricEditMessage(state, boolean) {state.loadingSpinnerHistoricEditMessage = boolean},
        deleteNofitication(state, id) {state.notifications = state.notifications.filter(item => item.id !== id)},
        setChartDashboardQuantityCallsLastDays(state, chart) {state.chartDashboardQuantityCallsLastDays = chart},
    },

    actions: {
        ActionSetInformationsLogin({commit}, profile) {
            commit('defineIfAreSupport', profile.company_id === process.env.VUE_APP_SUPORT_COMPANY_ID);
            commit('defineIfAreClerk', profile.is_clerk);
            commit('defineIfAreClerkAdmin', profile.is_admin);
            commit('defineIfAreClerkCommon', (profile.is_clerk && !profile.is_admin));
        },

        ActionSetMessagesEditedHistoric({commit}, messages) {
            commit('setMessagesEditedHistoric', messages);
            commit('setLoadingSpinnerHistoricEditMessage', false);
        },

        ActionSetDashboardUsersCount({commit}, quantity) {
            commit('setCountsDashboardUsers', quantity);
            commit('setLoadingCardsDashUsers', false);
        },

        ActionSetMonthLimit({commit}, payload) {
            commit('setMonthlyLimit', payload);

            if (payload?.limit_messages && payload?.limit_messages > 0) {
                commit('setIfIsExceededMonthLimit', (payload?.quantity_sent ?? 0) > payload?.limit_messages);
            } else {
                commit('setIfIsExceededMonthLimit', false);
            }
        },

        ActionStartCountdown({commit, state}) {
            let seconds = 60;
            if (!state.countdownStarted) {
                commit('setCountdownStarted', true);
                const interval = setInterval(() => {
                    seconds = state.timeLeft - 1;
                    if (seconds === 0) {
                        clearInterval(interval);
                        commit('setTimeLeft', 'Tempo Expirado!');
                    } else {
                        commit('setTimeLeft', seconds);
                    }
                }, 1000);
            }
        },

        ActionSetProfile({commit}, profile) {
            commit('setProfile', profile);
        },

        ActionLogout(root) {
            //todo melhorar esse método para limpar os states quando o usuário sai.
            root.commit('setIfHasConfigBd', false);
        },

        ActionLoadFullPageCall({commit}) {
            commit('changeLoading', true);
            commit('setSpinnerLoadingTimelineCall', true);
            commit('setSpinnerLoadingChatCall', true);
        },

        ActionStartCurrentTime({commit}) {
            setInterval(() => {
                let dateString = new Date().toLocaleString('pt-BR', {timeZone: 'America/Sao_Paulo'});
                dateString = dateString.split('/');
                commit('setDateActual', (`${dateString[0]}/${dateString[1]}/${dateString[2]}`));
            }, 1000);
        },

        ActionUpdatePartialProfile({commit, state}, datas) {
            const newProfile = Object.assign({}, state.profile);

            for (const prop in datas) {
                newProfile[prop] = datas[prop];
            }

            commit('setProfile', newProfile);
            localStorage.setItem('profile', JSON.stringify(newProfile));
        },

        async ActionBuildChartDashboardUsersEvaders({commit}, values) {
            const el = document.getElementById('chart_dashboard_users_evaders');

            if (el) {
                const {data, categories} = values.datas;
                const colors = ['#0e5e4e', '#4dbd8e', '#085c3b', '#57772b', '#9baf76', '#4a5e2e', '#c1920c', '#ffcc33', '#ad8005',
                    '#d64c36', '#ff796c', '#b03228', '#383737', '#6f6f6f', '#a1a1a1', '#8336d6', '#b066ff', '#4b0c8c', '#0a3a75',
                    '#3a66a7', '#072d5a'];

                const options = {
                    series: [{data}],
                    chart: {height: 350, type: 'bar'},
                    colors: colors,
                    title: {text: 'Quantidade de Pessoas que Enviaram "Sair" por Empresa'},
                    noData: {
                        text: 'Nenhum Mensagem Localizada no Intervalo Selecionado!',
                        align: 'center',
                        verticalAlign: 'middle',
                        offsetX: 0,
                        offsetY: 0,
                        style: {color: '#000', fontSize: '14px', fontFamily: undefined},
                    },
                    plotOptions: {bar: {columnWidth: '45%', distributed: true}},
                    dataLabels: {enabled: false},
                    legend: {show: false},
                    xaxis: {categories, labels: {style: {colors: colors, fontSize: '12px'}}},
                };

                const chart = new ApexCharts(el, options);
                commit('setChartDahsboardUsersEvaders', chart);
            }
        },

        async ActionBuildChartDashboardUsers({commit}, values) {
            const el = document.getElementById('chart_dashboard_users_sends');

            if (el) {
                const {data, categories} = values.datas;
                const text = (values.days > 1) ? `${values.type} nos Últimos ${values.days} Dias` : `${values.type} no Último Ano`;
                const colors = ['#0e5e4e', '#4dbd8e', '#085c3b', '#57772b', '#9baf76', '#4a5e2e', '#c1920c', '#ffcc33', '#ad8005',
                    '#d64c36', '#ff796c', '#b03228', '#383737', '#6f6f6f', '#a1a1a1', '#8336d6', '#b066ff', '#4b0c8c', '#0a3a75',
                    '#3a66a7', '#072d5a'];

                const options = {
                    series: [{data}],
                    chart: {height: 350, type: 'bar'},
                    colors: colors,
                    title: {text},
                    noData: {
                        text: 'Nenhum Mensagem Localizada no Intervalo Selecionado!',
                        align: 'center',
                        verticalAlign: 'middle',
                        offsetX: 0,
                        offsetY: 0,
                        style: {color: '#000', fontSize: '14px', fontFamily: undefined},
                    },
                    plotOptions: {bar: {columnWidth: '45%', distributed: true}},
                    dataLabels: {enabled: false},
                    legend: {show: false},
                    xaxis: {categories, labels: {style: {colors: colors, fontSize: '12px'}}},
                };

                const chart = new ApexCharts(el, options);
                commit('setChartDahsboardUsersSends', chart);
            }
        },

        async ActionBuildChartAverageTimeByClerks({commit}, values) {
            const {series, categories} = values.data;
            const el = document.getElementById('chart_average_time');

            if (el) {
                const options = {
                    chart: {id: 'average_time', height: 300, type: 'bar'},
                    colors: ['#0e5e4e', '#57772b', '#c1920c', '#d64c36', '#383737', '#8336d6', '#0a3a75'],
                    plotOptions: {bar: {columnWidth: '55%', distributed: true}},
                    dataLabels: {
                        enabled: true,
                        formatter: val => {
                            return [`${val} min./`, 'Atend.'];
                        },
                    },
                    legend: {show: false},
                    xaxis: {
                        categories,
                        labels: {
                            style: {
                                colors: ['#0e5e4e', '#57772b', '#c1920c', '#d64c36', '#383737', '#8336d6', '#0a3a75'],
                                fontSize: '12px',
                            },
                        },
                    },
                    noData: {
                        text: 'Nenhum Atendimento Localizado no Intervalo Selecionado!',
                        align: 'center',
                        verticalAlign: 'middle',
                        offsetX: 0,
                        offsetY: 0,
                        style: {color: '#000', fontSize: '14px', fontFamily: undefined},
                    },
                    yaxis: {title: {text: 'Minutos'}},
                    title: {text: `Média de Tempo por Atendimento nos Últimos ${values.days} Dias`},
                    series: [{data: series}],
                };

                const chart = new ApexCharts(el, options);

                commit('setChartAverageTimeClerk', chart);
            }
        },

        async ActionBuildChartCallsByCategories({commit}, values) {
            const el = document.getElementById('chart_calls_by_categories');
            const {days} = values;

            if (el) {
                const {data} = values;
                const series = data.series.map(item => parseInt(item));

                const options = {
                    series,
                    labels: data.categories,
                    title: {
                        text: `Atendimentos Finalizados por Categorias (${days} Dias)`,
                        margin: 20,
                        style: {fontSize: '15px'},
                    },
                    noData: {
                        text: `Nenhum Atendimento Localizado no Intervalo Selecionado!`,
                        align: 'center',
                        verticalAlign: 'middle',
                        offsetX: 0,
                        offsetY: 0,
                        style: {color: '#000', fontSize: '14px', fontFamily: undefined},
                    },
                    responsive: [{breakpoint: 480, options: {chart: {width: 200}, legend: {position: 'bottom'}}}],
                    plotOptions: {pie: {donut: {labels: {show: true, total: {show: true}}}}},
                    chart: {id: 'categories', type: 'donut', height: 300},
                };

                const chart = new ApexCharts(el, options);

                commit('setChartCallsByCategories', chart);
            }
        },

        async ActionBuildChartCallsByClerks({commit}, values) {
            const el = document.getElementById('chart_calls_by_clerks');
            const {days} = values;

            if (el) {
                const {data} = values;
                const series = data.series.map(item => parseInt(item));

                const options = {
                    series,
                    labels: data.categories,
                    title: {
                        text: `Atendimentos Finalizados por Atendente (${days} Dias)`,
                        margin: 20,
                        style: {fontSize: '15px'},
                    },
                    noData: {
                        text: `Nenhum Atendimento Localizado no Intervalo Selecionado!`,
                        align: 'center',
                        verticalAlign: 'middle',
                        offsetX: 0,
                        offsetY: 0,
                        style: {color: '#000', fontSize: '14px', fontFamily: undefined},
                    },
                    responsive: [{breakpoint: 480, options: {chart: {width: 200}, legend: {position: 'bottom'}}}],
                    plotOptions: {pie: {donut: {labels: {show: true, total: {show: true}}}}},
                    chart: {id: 'calls_by_clerks', type: 'donut', height: 300},
                };

                const chart = new ApexCharts(el, options);

                commit('setChartCallsByClerks', chart);
            }
        },

        async ActionBuildChartMessageTypes({commit}, values) {
            const el = document.getElementById('chart_message_types_send');

            if (el) {
                const {data, days} = values;
                const series = data.series.map(item => parseInt(item));

                const options = {
                    series,
                    labels: data.categories,
                    title: {text: `Mensagens Enviadas por Tipo (${days} Dias)`, margin: 20, style: {fontSize: '15px'}},
                    responsive: [{breakpoint: 480, options: {chart: {width: 200}, legend: {position: 'bottom'}}}],
                    plotOptions: {pie: {donut: {labels: {show: true, total: {show: true, color: '#373d3f'}}}}},
                    chart: {id: 'message_types', type: 'pie', height: 300, toolbar: {show: true, autoSelected: 'zoom'}},
                };

                commit('chartMessageTypesSend', new ApexCharts(el, options));
            }
        },

        ActionBuildChartCurrentlyCallsByClerks({commit}, values) {
            const el = document.getElementById('chart-currently-calls-by-calls');

            if (el) {
                const {series, categories} = values;

                const options = {
                    series,
                    title: {align: 'center', text: 'Atendimentos Atualmente por Atendentes'},
                    labels: categories,
                    responsive: [{breakpoint: 480, options: {chart: {width: 200}, legend: {position: 'bottom'}}}],
                    plotOptions: {pie: {donut: {labels: {show: true, total: {show: true, color: '#fff'}}}}},
                    chart: {
                        id: 'currently_calls_by_clerks',
                        type: 'pie',
                        height: 300,
                        toolbar: {show: true, autoSelected: 'zoom'},
                        events: {
                            dataPointSelection: (event, {opts}, config) => {
                                commit('setChartDataCurrentlyCallClerkSelected', {
                                    nickname: opts.labels[config.dataPointIndex],
                                    dataPointIndex: config.dataPointIndex,
                                });
                            },
                        },
                    },
                    noData: {
                        text: `Nenhum Atendimento em Andamento no Momento!`,
                        align: 'center',
                        verticalAlign: 'middle',
                        offsetX: 0,
                        offsetY: 0,
                        style: {color: '#000', fontSize: '14px', fontFamily: undefined},
                    },
                };

                const chart = new ApexCharts(el, options);

                commit('setChartCurrentlyCallsByClerk', chart);
            }
        },

        async ActionBuildChartCurrentlyCallsBySectors({commit}, values) {
            const el = document.getElementById('chart-currently-calls-by-sectors');

            if (el) {
                const {series, categories} = values;

                const options = {
                    series,
                    title: {align: 'center', text: 'Atendimentos Atualmente por Setores'},
                    labels: categories,
                    responsive: [{breakpoint: 480, options: {chart: {width: 200}, legend: {position: 'bottom'}}}],
                    plotOptions: {pie: {donut: {labels: {show: true, total: {show: true, color: '#fff'}}}}},
                    chart: {
                        id: 'currently_calls_by_sectors',
                        type: 'pie',
                        colors: ['#0e5e4e'],
                        height: 300,
                        toolbar: {show: true, autoSelected: 'zoom'},
                        events: {
                            dataPointSelection: (event, {opts}, config) => {
                                commit('setChartDataCurrentlyCallSectorSelected', {
                                    sector: opts.labels[config.dataPointIndex],
                                    dataPointIndex: config.dataPointIndex,
                                });
                            },
                        },
                    },
                    noData: {
                        text: `Nenhum Atendimento em Andamento no Momento!`,
                        align: 'center',
                        verticalAlign: 'middle',
                        offsetX: 0,
                        offsetY: 0,
                        style: {color: '#000', fontSize: '14px', fontFamily: undefined},
                    },
                };

                const chart = new ApexCharts(el, options);

                commit('setChartCurrentlyCallsBySector', chart);
            }
        },

        async ActionBuildChartDashboard({commit}, values) {
            const el = document.getElementById('chart');

            if (el) {
                const options = {
                    chart: {id: 'notifications', height: 300, type: 'area'},
                    title: {align: 'left', text: `Notificações de Vencimento/Atraso - Últimos ${values.days} dias`},
                    noData: {text: 'Nenhum dado para ser carregado', align: 'center', verticalAlign: 'middle'},
                    series: values.datas.series,
                    colors: ['#0e5e4e', '#57772b', '#c1920c', '#d64c36', '#383737', '#8336d6', '#0a3a75'],
                    xaxis: {categories: values.datas.categories, type: 'category'},
                    dataLabels: {enabled: false},
                };

                if (values.type === DashboardEnum.AllMessages) {
                    options.title.text = `Todas as Mensagens - Últimos ${values.days} dias`;
                } else if (values.type !== DashboardEnum.Notification) {
                    const by = values.type.split(' ').pop();

                    options.chart.type = 'bar';
                    options.title.text = `Atendimentos Finalizados por ${by} - Últimos ${values.days} dias`;
                    options.series = [{data: values.datas.series}];
                    options.colors = ['#0e5e4e'];
                }

                commit('setChart', new ApexCharts(el, options));
            }
        },

        async ActionBuildChartDashboardQuantityCallsLastDays({commit}, values) {
            const el = document.getElementById('chart_quantity_calls_last_days');

            if (el) {
                const text = `${values.type}${(values.days === '1') ? ` - Último ano` : ` - Últimos ${values.days} dias`}`;

                const options = {
                    series: values.datas,
                    chart: {id: 'quantity_calls_last_days', height: 300, type: 'bar'},
                    title: {align: 'left', text},
                    noData: {text: 'Nenhum dado para ser carregado', align: 'center', verticalAlign: 'middle'},
                    colors: ['#0e5e4e', '#57772b', '#c1920c', '#d64c36', '#383737', '#8336d6', '#0a3a75'],
                    dataLabels: {enabled: false},
                };

                const chart = new ApexCharts(el, options);

                commit('setChartDashboardQuantityCallsLastDays', chart);
            }
        },

        async ActionBuildChartPortAnalysis({commit}, values) {
            const el = document.getElementById('chart-port-analysis');

            if (el) {
                const {data, labels} = values;

                const options = {
                    chart: {id: 'port_analysis', type: 'bar', height: 300},
                    colors: ['#0e5e4e'],
                    plotOptions: {bar: {horizontal: false, columnWidth: '55%', endingShape: 'rounded'}},
                    labels,
                    series: [{name: 'Portas', data}],
                    dataLabels: {enabled: false},
                    yaxis: {title: {text: 'Quantidade de Portas'}},
                    fill: {opacity: 1},
                };

                commit('setChartPortAnalysis', new ApexCharts(el, options));
            }
        },

        async ActionBuildChartPortAnalysisPie({commit}, values) {
            const el = document.getElementById('chart-port-analysis-pie');

            if (el) {
                const {data, labels} = values;

                const options = {
                    chart: {id: 'port_analysis_pie', type: 'pie', height: 300},
                    colors: ['#0e5e4e', '#0E5E4ECC', '#0E5E4E99', '#0E5E4E66', '#0E5E4E33'],
                    responsive: [{breakpoint: 480, options: {chart: {width: 200}, legend: {position: 'bottom'}}}],
                    plotOptions: {
                        pie: {
                            donut: {
                                labels: {show: true, total: {show: true, color: '#fff'}, background: 'transparent'},
                            },
                            expandOnClick: true,
                        },
                    },
                    stroke: {show: true, width: 2},
                    labels,
                    series: data,
                    dataLabels: {enabled: true},
                    yaxis: {title: {text: 'Quantidade de Portas'}},
                    fill: {opacity: 1},
                };

                commit('setChartPortAnalysisPie', new ApexCharts(el, options));
            }
        },

        async ActionBuildChartHistoricSends({commit}, data) {
            const {series} = data;
            const el = document.getElementById('chart_historic_of_sends');

            if (el) {
                const options = {
                    series,
                    chart: {id: 'historic_sends', type: 'bar', height: 350},
                    title: {align: 'left', text: 'Histório de envios - Último ano'},
                    colors: ['#0e5e4e'],
                    plotOptions: {bar: {horizontal: false, columnWidth: '55%', endingShape: 'rounded'}},
                    dataLabels: {enabled: false},
                    stroke: {show: true, colors: ['transparent']},
                    yaxis: {title: {text: 'Quantidade enviada'}},
                    fill: {opacity: 1},
                };

                commit('setChartHistoricOfSends', new ApexCharts(el, options));
            }
        },

        async ActionSetContactsFromSupportDashboard({commit, dispatch, state}, findContactsInLocalStorage) {
            if (findContactsInLocalStorage) {
                const contacts = JSON.parse(localStorage.getItem('suport_contacts'));
                if (contacts) {
                    commit('setContacts', contacts);
                } else {
                    await dispatch('ActionSetContactsFromSupportDashboard', false);
                }
            } else {
                commit('setSpinnerLoadingSupportDashboard', true);
                await Support.getDashboard().then(async resp => {
                    commit('setSpinnerLoadingSupportDashboard', false);
                    commit('setContacts', resp.data);
                    localStorage.setItem('suport_contacts', JSON.stringify(state.contacts));
                }).catch(err => console.error(err));
            }
        },
    },

    getters: {
        getSignature: state => {
            if (state.profile.message_signature) {
                return state.profile.name.trim();
            }

            return null;
        },
    },
};
