import PermissionsEnum from '@/Enums/PermissionsEnum';

export default {
    namespaced: true,

    state: {
        canBot: false,
        canSurvey: false,
        canBirthday: false,
        canSchedule: false,
        canFeedback: false,
        canCampaign: false,
        canAdminPage: false,
        userPermissions: [],
        canAttendance: false,
        canChangeCampaignNumbers: false,
    },

    mutations: {
        setPermissionBot(state, boolean) {state.canBot = boolean},
        setIfIsAdmin(state, boolean) {state.canAdminPage = boolean},
        setPermissionBirthday(state, boolean) {state.canBirthday = boolean},
        setPermissionFeedback(state, boolean) {state.canFeedback = boolean},
        setPermissionSchedule(state, boolean) {state.canSchedule = boolean},
        setPermissionCampaign(state, boolean) {state.canCampaign = boolean},
        setPermissionSurvey(state, boolean) {state.canSurvey = boolean},
        setPermissionAttendance(state, boolean) {state.canAttendance = boolean},
        setUserPermissions(state, permissions) {state.userPermissions = permissions},
        setIfCanChangeCampaignNumbers(state, boolean) {state.canChangeCampaignNumbers = boolean},
    },

    actions: {
        ActionSetPermissions({commit}, profile) {
            const packPermissions = [];
            const {
                allow_atendance, allow_birthday, feedback_activated, bot_activated, id, company_id, allow_schedule,
                allow_campaign, allow_survey,
            } = profile;

            const isSuperAdmin = (id === process.env.VUE_APP_SUPER_ADMIN_ID) || (company_id === process.env.VUE_APP_SUPORT_COMPANY_ID);
            const isClerk = profile.is_clerk;
            const isClerkAdmin = profile.is_admin;

            let addManualNumbers = profile.add_manual_nums_camp || isClerk;

            if (!isClerk || isClerkAdmin) {
                if (allow_atendance || isClerkAdmin) {
                    packPermissions.push(PermissionsEnum.CALLS);
                }

                allow_birthday && packPermissions.push(PermissionsEnum.BIRTHDAY);
                feedback_activated && packPermissions.push(PermissionsEnum.FEEDBACK);
                bot_activated && packPermissions.push(PermissionsEnum.BOT);

                addManualNumbers = true;
            }

            isSuperAdmin && packPermissions.push(PermissionsEnum.SUPERADMIN);
            allow_campaign && packPermissions.push(PermissionsEnum.CAMPAIGN);
            allow_survey && packPermissions.push(PermissionsEnum.SURVEY);
            allow_schedule && packPermissions.push(PermissionsEnum.MESSAGE_SCHEDULING);
            allow_birthday && packPermissions.push(PermissionsEnum.BIRTHDAY);

            packPermissions.push(PermissionsEnum.BLACKLIST, PermissionsEnum.ERRORS, PermissionsEnum.CONTACTS, PermissionsEnum.TRIGGERED_MESSAGES);

            commit('setUserPermissions', packPermissions);
            commit('setPermissionAttendance', (!isClerk && allow_atendance));
            commit('setPermissionBirthday', allow_birthday);
            commit('setPermissionFeedback', (feedback_activated && (!isClerk || isClerkAdmin)));
            commit('setPermissionBot', (!isClerk && bot_activated));
            commit('setPermissionCampaign', allow_campaign);
            commit('setPermissionSurvey', allow_survey);
            commit('setPermissionSchedule', allow_schedule);
            commit('setIfIsAdmin', isSuperAdmin);
            commit('setIfCanChangeCampaignNumbers', addManualNumbers);
        },
    },

    getters: {
        hasPermission: (state) => (permission) => {
            return state.userPermissions.includes(permission);
        },
    },
};
