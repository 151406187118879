import {http} from './config';

export default {
    login: body => http.post('api/user/login', body),
    
    securityLogin: token => http.get('api/user/login/' + token),
    
    logout: () => http.get('api/user/logout'),

    showGetUser: () => http.get('api/user/show-auth-user'),

    refreshTokenSecurity: refreshToken => http.get('api/user/refreshToken/' + refreshToken),
}
