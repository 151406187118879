import Call from '@/services/Calls/Call';

export default {
    namespaced: true,

    state: {
        calls: {},
        clerks: [],
        clerk: null,
        myCalls: [],
        sectors: [],
        protocols: [],
        callsIndex: [],
        filterCall: {},
        transferences: [],
        callCategories: [],
        listCategories: [],
        callsItemsPage: [],
        conversationCall: [],
        quantityCurrentlyCall: 0,
        showProtocolsCallModal: false,
    },

    mutations: {
        setClerk(state, clerk) {state.clerk = clerk},
        setCalls(state, calls) {state.calls = calls;},
        setClerks(state, clerks) {state.clerks = clerks},
        setMyCalls(state, calls) {state.myCalls = calls},
        setSectors(state, sectors) {state.sectors = sectors},
        setCallIndex(state, calls) {state.callsIndex = calls},
        setFilterCall(state, filter) {state.filterCall = filter},
        setListCategories(state, list) {state.listCategories = list},
        setProtocols(state, protocols) {state.protocols = protocols},
        setCallsItemsPage(state, items) {state.callsItemsPage = items},
        setTransferences(state, historic) {state.transferences = historic},
        showProtocolsModal(state, bool) {state.showProtocolsCallModal = bool},
        setCallCategories(state, categories) {state.callCategories = categories},
        setQuantityCurrentlyCall(state, int) {state.quantityCurrentlyCall = int},
        setConversationCall(state, conversation) {state.conversationCall = conversation},
    },

    actions: {
        ActionSetCalls({commit, rootState}, calls) {
            const myCalls = calls.filter(call => call.idUserServicedBy === rootState.Layoutx.profile.id);

            myCalls.forEach(call => {
                const find = rootState.Chatx.newChats.find(chat => call.chatId === chat.chat_id);

                if (find) {
                    call.image = find.image;
                }
            });

            commit('setCalls', calls);
            commit('setMyCalls', myCalls);
        },

        ActionSetProtocolsModals({commit}, protocols) {
            commit('setProtocols', protocols);
            commit('showProtocolsModal', true);
        },

        ActionHiddenProtocolsModals({commit}) {
            commit('showProtocolsModal', false);
            commit('setProtocols', []);
        },

        ActionSetClerks({rootState, commit}, clerks) {
            commit('setClerks', clerks);
            if (clerks && clerks.length > 0) {
                const clerk = clerks.find(item => item.id === rootState.Layoutx.profile.id);

                commit('setClerk', clerk);
            }
        },

        ActionSetCallCategories({commit}, categories) {
            if (categories && categories.length > 0) {
                this.categories = categories;
                commit('setCallCategories', categories);
                const cat = categories.map(obj => {
                    const {text, id} = obj;
                    return {text, id};
                });

                commit('setListCategories', cat);
            }
        },

        ActionGetCallsIndex({commit}, data) {
            return Call.index(data.filter, data.page, data.perPage).then(resp => {
                commit('setCallIndex', resp.data.data);
                commit('setCallsItemsPage', resp.data);
            }).catch(err => err);
        },
    },

    getters: {},
};
