import Connection from '@/services/Connection';

export default async (to, from, next) => {
    if (to.name !== 'LoginComponent' && to.name !== 'SecurityLogin') {
        if (to.name === 'Home') {
            return await Connection.checkToken().then(() => next()).catch(() => next({name: 'LoginComponent'}));
        }
    }
    
    return next();
}
