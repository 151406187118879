export default Object.freeze({
    Notification: 'Mensagens de Notificação (Boletos)',
    AllMessages: 'Todas as Mensagens',
    AtendanceByClerk: 'Atendimentos por Atendente',
    AtendanceBySector: 'Atendimentos por Setor',
    AtendanceByCategory: 'Atendimentos por Categoria',
    QuantityUsersMessagesSendeds: 'Quantidade de Mensagens Disparadas',
    QuantityUsersMessagesDelivereds: 'Quantidade de Mensagens Entregues',

    sevenDays: '7 days',
    fifteenDays: '15 days',
    thirtyDays: '30 days',
    fortyFiveDays: '45 days',
    sixtyDays: '60 days',
    lastYear: '1 year',
});
